import React from 'react';

import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import {
  BlogPostCollection,
  Copyright,
  NewsletterForm,
  Link,
  Logo,
  SEO,
} from '../components';
import theme from '../util/theme';
import { graphql } from 'gatsby';

const useStyles = makeStyles(theme => ({
  featuredImageContainer: {
    flexGrow: 12,
    borderTop: `5px solid ${theme.palette.secondary.main}`,
    margin: 0,
    backgroundImage:
      'url(https://source.unsplash.com/collection/9411598/1200x800)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  header: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',

    alignItems: 'center',
  },
  logo: {
    margin: '2rem auto 0rem auto',
    fill: '#fff',
    color: '#fff',
  },
  overline: {},
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  newsletterContainer: {
    marginTop: theme.spacing(4),
  },
  toolbarLink: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
  },
  toolbarSecondary: {
    justifyContent: 'center',
    overflowX: 'auto',
    minHeight: '0px',
  },
}));

const sections = [
  {
    title: 'Podcast',
    url: '/podcast',
  },
  {
    title: 'Blog',
    url: '/blog',
  },
];

const HomePage = ({ data }) => {
  const { podcasts, posts } = data;
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <SEO title="Sign up for early access" />
      <div className={classes.root}>
        <CssBaseline />
        <Container maxWidth="md" className={classes.header}>
          <Logo className={classes.logo} />
          <span>
            <Typography variant="overline">Workout tracking for</Typography>
            <Typography
              variant="overline"
              color="secondary"
              className={classes.overline}
            >
              <i>{' everyone'}</i>
            </Typography>
          </span>
          <Toolbar
            component="nav"
            variant="dense"
            className={classes.toolbarSecondary}
          >
            {sections.map(section => (
              <Link
                color="inherit"
                noWrap
                key={section.title}
                variant="body2"
                to={section.url}
                className={classes.toolbarLink}
              >
                {section.title}
              </Link>
            ))}
          </Toolbar>
        </Container>
        <Container
          disableGutters
          maxWidth={false}
          className={classes.featuredImageContainer}
        >
          <Container maxWidth="sm">
            <NewsletterForm />
          </Container>
        </Container>
      </div>
      <Container maxWidth="lg" className={classes.newsletterContainer}>
        <Typography variant="h2">News</Typography>
        <BlogPostCollection posts={[posts.nodes[0]]} />

        <Typography variant="h2">Listen to the podcast</Typography>
        <Box md={4}>
          {podcasts.nodes.map(podcast => (
            <iframe
              key={podcast.id}
              title={podcast.title}
              src={podcast.link.replace('/s/', '/e/')}
              width="100%"
              height="180"
              frameBorder="0"
              scrolling="no"
              seamless
              style={{ width: '100%', height: '180px' }}
            ></iframe>
          ))}
        </Box>
      </Container>
      <Container maxWidth="md">
        <Box my={2}>
          <Copyright />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default HomePage;

export const query = graphql`
  {
    posts: allMdx(
      filter: { frontmatter: { type: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        excerpt(pruneLength: 300)
        fileAbsolutePath
        frontmatter {
          author
          heroImage {
            author {
              name
              url
            }
            url
          }
          date
          subtitle
          tags
          title
        }
      }
    }
    podcasts: allFeedPodcast(sort: { fields: itunes___episode, order: DESC }) {
      nodes {
        id
        title
        pubDate
        link
        content {
          encoded
        }
        enclosure {
          url
          length
        }
        isoDate
        itunes {
          image {
            attrs {
              href
            }
          }
          episode
          duration
        }
      }
    }
  }
`;
